<template>
  <div class="page-wrapper section-space--inner--120">
    <div class="blog-section">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="row">
              <div v-for="blog in blogList" :key="blog.id" class="col-sm-6">
                <BlogPost :blog="blog" />
              </div>
            </div>
            <div class="row ">
              <div class="col">
                <b-pagination v-model="pageNum" class="section-space--top--30" size="lg" :per-page="limit" :total-rows="total" align="center" limit="10" hide-goto-end-buttons @change="goToPage" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BlogPost from '@/components/BlogPost'
export default {
  components: {
    BlogPost
  },
  data() {
    return {
      blogList: [],
      pageNum: 1,
      limit: 4,
      total: 0
    }
  },
  async mounted() {
    await this.refreshList()
  },
  methods: {
    async refreshList() {
      const res = await this.$api.getBlogList({
        pageNum: this.pageNum,
        limit: this.limit
      })
      this.blogList = res.blogList
      this.total = res.total
    },
    async goToPage(num) {
      this.pageNum = num
      await this.refreshList()
    }
  }
}
</script>
