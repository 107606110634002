<template>
  <div class="blog-post-slider__single-slide blog-post-slider__single-slide--grid-view">
    <div class="blog-post-slider__image section-space--bottom--30">
      <router-link :to="'/blog-details?id='+blog.id">
        <div class="img-box">
          <img :src="blog.thumbUrl" class="img-fluid" alt="blog-image">
        </div>
      </router-link>
    </div>
    <div class="blog-post-slider__content">
      <h3 class="post-title">
        <router-link :to="'/blog-details?id='+blog.id">{{ blog.title }}</router-link>
      </h3>
      <router-link :to="'/blog-details?id='+blog.id" class="see-more-link">READ MORE</router-link>
    </div>
  </div>
</template>

<script>
export default {
  props: ['blog']
}
</script>

<style lang="scss" scoped>
img {
  height: 30vmin;
  object-fit: cover;
}
h3{
  position: relative;
}
a{
  width: 100%;
  white-space: nowrap;
  text-overflow:ellipsis;
  overflow:hidden;
}
</style>
