<template>
  <div class="main-container">

    <Header />

    <Breadcrumb :items="items" title="资讯" />

    <BlogMainWrapper />

    <BrandCarousel add-class="grey-bg" />

    <Footer />

    <OffCanvasMobileMenu />

    <!-- back to top start -->
    <back-to-top class="scroll-top" bottom="60px">
      <i class="ion-android-arrow-up" />
    </back-to-top>
    <!-- back to top end -->
  </div>
</template>

<script>
import Header from '@/components/Header'
import Breadcrumb from '../components/Breadcrumb'
import BlogMainWrapper from '../components/sections/BlogMainWrapper'
import BrandCarousel from '../components/BrandCarousel'
import Footer from '../components/Footer'
import OffCanvasMobileMenu from '@/components/OffCanvasMobileMenu'

export default {
  components: {
    Header,
    Breadcrumb,
    BlogMainWrapper,
    BrandCarousel,
    Footer,
    OffCanvasMobileMenu
  },
  data() {
    return {
      items: [
        {
          text: 'Information',
          active: true
        }
      ]
    }
  },
  metaInfo: {
    title: '乌节国际 - 资讯',
    htmlAttrs: {
      lang: 'zh-cn',
      amp: true
    }
  }
}
</script>

